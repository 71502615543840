import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-header border-0" }
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = { class: "card-toolbar m-0" }
const _hoisted_4 = {
  class: "nav nav-stretch fs-5 fw-bold nav-line-tabs nav-line-tabs-2x border-transparent",
  role: "tablist"
}
const _hoisted_5 = ["id", "href"]
const _hoisted_6 = { class: "card-body pt-0" }
const _hoisted_7 = {
  id: "kt_referred_users_tab_content",
  class: "tab-content"
}
const _hoisted_8 = {
  id: "kt_customer_details_invoices_1",
  class: "py-0 tab-pane fade active show",
  role: "tabpanel"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  id: "kt_customer_details_invoices_2",
  class: "py-0 tab-pane fade",
  role: "tabpanel"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = {
  id: "kt_customer_details_invoices_3",
  class: "py-0 tab-pane fade",
  role: "tabpanel"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  id: "kt_customer_details_invoices_4",
  class: "py-0 tab-pane fade",
  role: "tabpanel"
}
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`card pt-2 ${_ctx.cardClasses}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.t("myInvoices")), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years, (year, index) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "nav-item",
              role: "presentation",
              key: year.date
            }, [
              _createElementVNode("a", {
                id: `kt_referrals_${year}_tab`,
                class: _normalizeClass(["nav-link text-active-primary ms-3", {
                active: year.date === new Date().getFullYear(),
              }]),
                "data-bs-toggle": "tab",
                role: "tab",
                href: '#kt_customer_details_invoices_' + (index + 1)
              }, _toDisplayString(year.date === new Date().getFullYear() ? _ctx.t("thisYear") : year.date), 11, _hoisted_5)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.orders)
            ? (_openBlock(), _createBlock(_component_Datatable, {
                key: 0,
                "table-header": _ctx.tableHeader,
                "table-data": _ctx.filterTable(_ctx.orders, _ctx.years[0]),
                "rows-per-page": 5
              }, {
                "cell-order": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.order), 1)
                ]),
                "cell-amount": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`text-${invoice.color}`)
                  }, _toDisplayString(invoice.amount), 3)
                ]),
                "cell-status": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`badge badge-light-${invoice.status.state}`)
                  }, _toDisplayString(invoice.status.label), 3)
                ]),
                "cell-date": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.date), 1)
                ]),
                "cell-invoice": _withCtx(({ row: invoice }) => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.goToPaymentService(invoice.order)),
                    class: "btn btn-sm btn-light btn-active-light-primary"
                  }, _toDisplayString(_ctx.t("download")), 9, _hoisted_9)
                ]),
                _: 1
              }, 8, ["table-header", "table-data"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_10, [
          (_ctx.orders)
            ? (_openBlock(), _createBlock(_component_Datatable, {
                key: 0,
                "table-header": _ctx.tableHeader,
                "table-data": _ctx.filterTable(_ctx.orders, _ctx.years[1]),
                "rows-per-page": 5
              }, {
                "cell-order": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.order), 1)
                ]),
                "cell-amount": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`text-${invoice.color}`)
                  }, _toDisplayString(invoice.amount), 3)
                ]),
                "cell-status": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`badge badge-light-${invoice.status.state}`)
                  }, _toDisplayString(invoice.status.label), 3)
                ]),
                "cell-date": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.date), 1)
                ]),
                "cell-invoice": _withCtx(({ row: invoice }) => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.goToPaymentService(invoice.order)),
                    class: "btn btn-sm btn-light btn-active-light-primary"
                  }, _toDisplayString(_ctx.t("download")), 9, _hoisted_11)
                ]),
                _: 1
              }, 8, ["table-header", "table-data"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.orders)
            ? (_openBlock(), _createBlock(_component_Datatable, {
                key: 0,
                "table-header": _ctx.tableHeader,
                "table-data": _ctx.filterTable(_ctx.orders, _ctx.years[2]),
                "rows-per-page": 5
              }, {
                "cell-order": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.order), 1)
                ]),
                "cell-amount": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`text-${invoice.color}`)
                  }, _toDisplayString(invoice.amount), 3)
                ]),
                "cell-status": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`badge badge-light-${invoice.status.state}`)
                  }, _toDisplayString(invoice.status.label), 3)
                ]),
                "cell-date": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.date), 1)
                ]),
                "cell-invoice": _withCtx(({ row: invoice }) => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.goToPaymentService(invoice.order)),
                    class: "btn btn-sm btn-light btn-active-light-primary"
                  }, _toDisplayString(_ctx.t("download")), 9, _hoisted_13)
                ]),
                _: 1
              }, 8, ["table-header", "table-data"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          (_ctx.orders)
            ? (_openBlock(), _createBlock(_component_Datatable, {
                key: 0,
                "table-header": _ctx.tableHeader,
                "table-data": _ctx.filterTable(_ctx.orders, _ctx.years[3]),
                "rows-per-page": 5
              }, {
                "cell-order": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.order), 1)
                ]),
                "cell-amount": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`text-${invoice.color}`)
                  }, _toDisplayString(invoice.amount), 3)
                ]),
                "cell-status": _withCtx(({ row: invoice }) => [
                  _createElementVNode("span", {
                    class: _normalizeClass(`badge badge-light-${invoice.status.state}`)
                  }, _toDisplayString(invoice.status.label), 3)
                ]),
                "cell-date": _withCtx(({ row: invoice }) => [
                  _createTextVNode(_toDisplayString(invoice.date), 1)
                ]),
                "cell-invoice": _withCtx(({ row: invoice }) => [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.goToPaymentService(invoice.order)),
                    class: "btn btn-sm btn-light btn-active-light-primary"
                  }, _toDisplayString(_ctx.t("download")), 9, _hoisted_15)
                ]),
                _: 1
              }, 8, ["table-header", "table-data"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 2))
}