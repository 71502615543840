
import { defineComponent, computed, ref } from "vue";
import { cardCodes, cardTypeCodes, findIconCard} from "@/store/enums/cardCodes";
import { detectCard } from "@/core/helpers/converters";
import {replaceMonth} from "@/store/enums/months";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "payment-methods",
  props: {
    cardClasses: String,
    card_first6: String,
    card_expiry_month: String,
    card_expiry_year: String,
  },
  setup(props) {
    const { t } = useI18n();
    const first6 = ref(props.card_first6);

    const getTypeCard = computed(() => {
      if (first6.value) {
        const value = first6.value;
        return detectCard(props);
      }
      return '';
    });

    const getMouth = computed(() => {
      return replaceMonth(props.card_expiry_month);
    });

    return {
      getTypeCard,
      getMouth,
      t,
    };
  },
});
