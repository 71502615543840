
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { replaceMonth } from "@/store/enums/months";
import { InvoicesTableItem } from "@/assets/ts/components/InvoicesComponent";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { useI18n } from "vue-i18n";
import config from "@/core/config/APIConfig";

export default defineComponent({
  name: "invoices",
  props: {
    cardClasses: String,
    orders: Array,
  },
  data() {
    return {
      years: [
        { date: new Date().getFullYear() },
        { date: new Date().getFullYear() - 1 },
        { date: new Date().getFullYear() - 2 },
        { date: new Date().getFullYear() - 3 },
      ],
    };
  },
  components: {
    Datatable,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();

    const tableHeader = ref([
      {
        name: t("paymentId"),
        key: "order",
        sortable: true,
      },
      {
        name: t("summa"),
        key: "amount",
        sortable: true,
      },
      {
        name: t("status"),
        key: "status",
        sortingField: "status.label",
        sortable: true,
      },
      {
        name: t("date"),
        key: "date",
        sortable: true,
      },
      {
        name: t("invoice"),
        key: "invoice",
        sortable: false,
      },
    ]);

    onMounted(() => {
      if (props.orders) {
        let sum = 0;
        props.orders.forEach((item: any) => {sum += +item.payment_cost})
        store.dispatch(Actions.SET_SUM_AMOUNT_ACT, sum)
      }
    })

    const filterTable = (ordersList, year) => { 
      const arr: InvoicesTableItem[] = [];
      ordersList.forEach((item) => {
        if (item) {
          const createdDate = item.createdAt.slice(0, 4);
          if (createdDate == year.date) {
            const it = {
              date: item?.createdAt ? moment(item.createdAt).format("MMM DD, YYYY") : "",
              order: item.payment_id,
              course_name: "d",
              color: item.status,
              amount: item.payment_cost,
              status: {
                label: item.status,
                state: item.status === ("failed" && "canceled") ? "danger" : "success",
              },
            };
            arr.push(it);
          }
        }
      })
      return arr;
    };

    const goToPaymentService = (paymentId) => {
      if (paymentId && config.yookassaSearch) {
        window.open(config.yookassaSearch + paymentId, "_blank");
      }
    };

    return {
      tableHeader,
      filterTable,
      goToPaymentService,
      t,
    };
  },
});
