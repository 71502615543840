
import { defineComponent, onMounted, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { Statuses } from "@/store/enums/StatusEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

interface EditSubData {
  id: string;
  paymentCost: string;
  group: string;
  partnerReferral: string;
  status: string;
  nextPayment: string;
}

export default defineComponent({
  name: "edit-sub-modal-prop",
  components: {},
  props: {
    id: String,
    paymentCost: String,
    group: String,
    partnerReferral: String,
    status: String,
    nextPayment: String,
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const targetData = ref<EditSubData>({
      id: "",
      paymentCost: "",
      group: "",
      partnerReferral: "",
      status: "",
      nextPayment: "",
    });

    onMounted(() => {
      targetData.value['_id'] = props.id ? props.id : ""
      targetData.value.paymentCost = props.paymentCost ? props.paymentCost : ""
      targetData.value.group = props.group ? props.group : ""
      targetData.value.partnerReferral = props.partnerReferral
          ? props.partnerReferral
          : ""
      targetData.value.status = props.status ? props.status : ""
      targetData.value.nextPayment = props.nextPayment ? props.nextPayment : ""
    });

    const rules = ref({
      paymentCost: [
        {
          required: false,
          message: "Please input Payment cost",
          trigger: "blur",
        },
      ],
      group: [
        {
          required: false,
          message: "Please input group",
          trigger: "blur",
        },
      ],
      partnerReferral: [
        {
          required: false,
          message: "Please input referral",
          trigger: "blur",
        },
      ],
      status: [
        {
          required: false,
          message: "Please select status",
          trigger: "change",
        },
      ],
      nextPayment: [
        {
          required: false,
          message: "Please select date",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          // Данные формы
          // targetData.value

          let data = {
            _id: targetData.value['_id'],
            payment_cost: targetData.value.paymentCost,
            group_name: targetData.value.group,
            status: targetData.value.status,
            partner: targetData.value.partnerReferral,
            date_expired: targetData.value.nextPayment
          }

          store
              .dispatch(Actions.EDIT_SUBSCRIPTION_DETAILS, data)
              .then(() => {
                store
                  .dispatch(Actions.GET_SUBSCRIBERS_ONE, { _id: data['_id'] })
                  .then(() => {
                    loading.value = false;
                    Swal.fire({
                      text: t("processDone"),
                      icon: "success",
                      buttonsStyling: false,
                      confirmButtonText: t("good"),
                      customClass: {
                        confirmButton: "btn btn-primary",
                      },
                    }).then(() => {
                      hideModal(newTargetModalRef.value);
                    });
                  })
              })
              .catch(() => {
                Swal.fire({
                  text: 'Error',
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: t("tryAgain"),
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              })

        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("good"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      targetData,
      Statuses,
      submit,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      t,
    };
  },
});
