
import { computed, defineComponent, ref, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { statusState } from "@/core/helpers/converters";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import NewCardModal from "@/components/modals/forms/NewCardModal.vue";
import Invoices from "@/components/subscriptions/cards/overview/Invoices.vue";
import PaymentMethods from "@/components/subscriptions/cards/overview/PaymentMethods.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { replaceMonth } from "@/store/enums/months";
import EditSubModal from "@/components/modals/forms/EditSubModalProp.vue";
import moment from "moment/moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "customer-details",
  components: {
    Invoices,
    PaymentMethods,
    Dropdown3,
    EditSubModal,
    /*NewCardModal,*/
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { t } = useI18n();
    const componentKey = ref(0);
    const Amount = ref(0);
    const ordrs = ref([]);
    const da = ref({
      id: "-",
      fullName: "-",
      phone: "-",
      email: "-",
      earningTotal: "-",
      role: "-",
      cardFirst6: "-",
      cardExpiryYear: "-",
      cardExpiryMonth: "-",
      paymentCost: "-",
      groupName: "-",
      courseName: "-",
      partner: "-",
      status: "-",
      state: "-",
      createdAt: "-",
      dateExpired: "-",
      date_expired_debtor: "",
    });
    const now = moment(new Date());
    const getCurses = ref([]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("subscriptionDetails"), [t("subscriptions")]);

      /**
       * Get all courses array
       */
      const courses = store.getters.getAllCurses;
      const arr = courses.map((item) => {
        return {
          _id: item._id,
          course_name: item.course_name ? item.course_name : "-",
          course_description: item.course_description
            ? item.course_description
            : "-",
          course_icon: item.course_icon ? item.course_icon : "-",
          __v: item.__v ? item.__v : 0,
          payment_cost: item.payment_cost ? item.payment_cost : "-",
        };
      });
      getCurses.value = arr;

      /**
       * Get main data
       */
      store
        .dispatch(Actions.GET_SUBSCRIBERS_ONE, { _id: route.params.id })
        .then(() => {
          ordrs.value = store.getters.subscribersOne.orders;
          da.value.id = store.getters.subscribersOne._id
            ? store.getters.subscribersOne._id
            : "-";
          da.value.fullName = store.getters.subscribersOne.user.fname
            ? store.getters.subscribersOne.user.fname +
              " " +
              store.getters.subscribersOne.user.lname
            : "-";
          setCurrentPageBreadcrumbs(da.value.fullName, [t("subscriptions")]);
          da.value.phone = store.getters.subscribersOne.user.phone
            ? store.getters.subscribersOne.user.phone
            : "-";
          da.value.email = store.getters.subscribersOne.user.email
            ? store.getters.subscribersOne.user.email
            : "-";
          da.value.earningTotal = store.getters.subscribersOne.earningTotal
            ? store.getters.subscribersOne.earningTotal
            : "-";
          da.value.role = store.getters.subscribersOne.user.role
            ? store.getters.subscribersOne.user.role
            : "-";
          da.value.cardFirst6 = store.getters.subscribersOne.card_first6
            ? store.getters.subscribersOne.card_first6
            : "-";
          da.value.cardExpiryYear = store.getters.subscribersOne
            .card_expiry_year
            ? store.getters.subscribersOne.card_expiry_year
            : "-";
          da.value.cardExpiryMonth = store.getters.subscribersOne
            .card_expiry_month
            ? store.getters.subscribersOne.card_expiry_month
            : "-";
          da.value.paymentCost = store.getters.subscribersOne.payment_cost
            ? store.getters.subscribersOne.payment_cost
            : "-";
          da.value.groupName = store.getters.subscribersOne.group_name
            ? store.getters.subscribersOne.group_name
            : "-";
          da.value.partner = changedData.value['partner']
            ? changedData.value['partner']
            : "-";
 
          da.value.status = changedData.value['status']
            ? changedData.value['status']
            : "-";

          da.value.state = changedData.value['status']
            ? statusState(changedData.value['status'])
            : "-";

          da.value.courseName = store.getters.subscribersOne.course_id
            ? getCourseName(store.getters.subscribersOne.course_id)
            : "-";    

          let duration = moment.duration(moment(store.getters.subscribersOne.date_expired_debtor).diff(now));

          da.value.date_expired_debtor = store.getters.subscribersOne
            .date_expired_debtor
            ? duration.asDays().toFixed(0)
            : "1";
          if (store.getters.subscribersOne.createdAt) {
            const date = store.getters.subscribersOne.createdAt;
            da.value.createdAt = moment(date).format('MMM DD, YYYY');;
          }
          if (changedData.value['date_expired']) {
            const date = changedData.value['date_expired']
            da.value.dateExpired = moment(date).format('MMM DD, YYYY');
          }
          // changing the key for re-rendering Invoices component
          componentKey.value += 1;
        })
        .then(() => {
          Amount.value = store.getters.getSumAmount
            ? store.getters.getSumAmount
            : 0;
        });
    });

    const dateFormat = (date: string) => {
      return moment(date).format('MMM DD, YYYY');
    }

    const orders = computed(() => {
      return store.getters.subscribersOne.orders
        ? store.getters.subscribersOne.orders
        : [];
    });

    const changedData = computed(() => {
      return store.getters.subscribersOne;
    });

    /**
     * Find course name by course ID
     */
    const getCourseName = (courseId) => {
      let coursesArr = getCurses.value,
          courseName,
          courseObj;
      if (coursesArr.length) {
        courseObj = coursesArr.find(item => courseId === item['_id']);
        courseName = courseObj?.course_name ?? "";
      }
      return courseName;
    };

    const telto = (phone) => {
      return "tel:" + phone;
    };

    return {
      changedData,
      status,
      da,
      orders,
      componentKey,
      ordrs,
      Amount,
      dateFormat,
      getCourseName,
      telto,
      t,
    };
  },
});
